

<script>
import { onBeforeMount, computed, /*reactive*/ } from 'vue';
import { useRouter, useRoute } from 'vue-router';
//import firebase from 'firebase/compat/app';
import { useStore } from 'vuex';
import { signOut } from 'firebase/auth';
import { onAuthStateChanged } from "firebase/auth";
import { Swal, auth } from './main';


export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    //store.dispatch('logout');

    onBeforeMount(() => {
      //alert("se ejecuto onBeforeMount en App.vue");
      onAuthStateChanged(auth, user => {
        if (!user) {
          console.log("App.vue = no hay usuario logueado");
          store.dispatch('actualizarUsuarioLogueado', { email: "ninguno", urlImagen: "ninguna", permisos: "ninguno" });
          router.replace('/login');
        } else if (route.path == "/login") {
          router.replace('/');
        }
      });
    });

    //sweetAlertCerrarSesion.isVisible();

    const cerrarSesion = async () => {
      console.clear();
      console.log("se ejecutó cerrarSesion");

      Swal.fire({
        icon: "info",
        showConfirmButton: false,
        timerProgressBar: true,
        allowOutsideClick: false,
        title: 'Cerrando sesión',
        text: "Por favor espere...",
        didOpen: () => { Swal.showLoading() },
        color: '#716add',
        backdrop: `rgba(138,138,138,0.8)`
      })

      //await store.dispatch('limpiarEstado', 'entrenadores');

      signOut(auth).then(() => {
        console.log("cierre sesion exitoso, procediendo a limpiar estados")
        store.dispatch('actualizarUsuarioLogueado', { email: "ninguno", urlImagen: "ninguna", permisos: "ninguno" })
        store.dispatch('limpiarEstado', { coleccion: "estudiantes", datos: "ninguno" });
        Swal.close();
      }).catch((error) => {
        console.log(error)
      });
      /*
      await signOut(auth).then(() => {
        //No limpio todos los estados ya que si no se demora mucho y no muestra el backdrop correctamente
        store.dispatch('actualizarUsuarioLogueado', { email: "ninguno", urlImagen: "ninguna", permisos: "ninguno" })
        store.dispatch('limpiarEstado', { coleccion: "estudiantes", datos: "ninguno" });
        Swal.close();
      });*/
    }

    return {
      cerrarSesion,
      usuario: computed(() => store.state.user),
      permisosUsuario: computed(() => store.state.user.permisos)
    }
  }
}
</script>

<template>
  <link rel="icon" type="image/png" href="./assets/favicon.ico" />
  <!--<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>-->
  <div class="header" v-if="usuario.email == 'ninguno'">
    <!--<h3>{{estudiantes}}</h3>-->
    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <img v-if="usuario.email == 'ninguno'" width="300" height="100" src="./assets/logo.png" class="logo" />
        </div>
      </div>
    </div>
    <!--{{usuario}}-->
    <!--{{permisosUsuario}}-->
    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <img v-if="usuario.email != 'ninguno'" width="300" height="100" src="./assets/logo.png" class="logo" />
        </div>
        <div class="col">
          <h2 v-if="usuario.email != 'ninguno'" style="font-weight: bold;">ADMON</h2>
        </div>
        <div class="col">
          <h3 v-if="usuario.email != 'ninguno'">{{ usuario.email }}</h3>
        </div>
        <div class="col">
          <img v-if="usuario.email != 'ninguno'" :src="usuario.urlImagen" alt="Avatar" class="avatar" />
        </div>
      </div>
    </div>
  </div>

  <nav v-if="!$route.meta.hideNavbar" class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <img width="150" height="50" src="./assets/logo.png" class="logo" />

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false"><span class="material-icons md-24">picture_in_picture</span>Diplomas</a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!--v-if="permisosUsuario.crearEstudiante"-->
              <li>
                <router-link class="dropdown-item" to="/crearDiploma">Crear / Eliminar</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false"><span class="material-icons md-24">groups</span>Cursos</a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!--v-if="permisosUsuario.crearEstudiante"-->
              <li>
                <router-link class="dropdown-item" to="/crearEditarCurso">Crear / editar / eliminar</router-link>
                <router-link class="dropdown-item" to="/generarExcelCurso">Generar Excel del curso</router-link>
                <router-link class="dropdown-item" to="/generarExcelCursosProximosVencer">Generar Excel cursos proximos a
                  vencer</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false"><span class="material-icons md-24">account_circle</span>Estudiantes</a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!--v-if="permisosUsuario.crearEstudiante"-->
              <li>
                <router-link class="dropdown-item" to="/crearEditarEstudiante">Crear/Editar</router-link>
                <router-link class="dropdown-item" to="/eliminarEstudiantes">Eliminar</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false"><span class="material-icons md-24">business</span>Empresas</a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!--v-if="permisosUsuario.crearEstudiante"-->
              <li>
                <router-link class="dropdown-item" to="/crearEditarEmpresa">Crear/editar</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false"><span class="material-icons md-24">list_alt</span>Datos</a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!--v-if="permisosUsuario.crearEstudiante"-->
              <li>
                <router-link class="dropdown-item" to="/crearCargo">Crear cargo</router-link>
                <router-link class="dropdown-item" to="/crearAreaDeTrabajo">Crear area de trabajo</router-link>
                <router-link class="dropdown-item" to="/crearSectorLaboral">Crear sector laboral</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false"><span class="material-icons md-24">download</span>BackUp</a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!--v-if="permisosUsuario.crearEstudiante"-->
              <li>
                <router-link class="dropdown-item" to="/backUp">Generar JSON</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">

            <button class="nav-link btn btn-danger" @click="cerrarSesion" aria-current="page">
              <span class="material-icons md-24">logout</span>Cerrar Sesion</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<style>
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v139/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  /*-moz-font-feature-settings: 'liga';*/
  -moz-osx-font-smoothing: grayscale;
  vertical-align: -6px;
  /*border: solid 1px #ccc;*/
  /*margin: 0px 0px 0px 0px;*/
}

.avatar {
  vertical-align: middle;
  width: 60px;
  height: 60px;
  border-radius: 60%;
}

.header {
  overflow: hidden;
  padding: 20px 20px;
  background: #e5e8e8;
  overflow: hidden;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.5);
  padding-bottom: 30px;
  /*text-align: center;*/
}

body {
  background: #ffffff;
  color: #2c3e50;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

a {
  color: inherit;
}

.loader-line {
  width: 900px;
  height: 7px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 1px auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 7px;
  width: 40%;
  background-color: rgb(80, 89, 255);
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }

  50% {
    left: 20%;
    width: 80%;
  }

  100% {
    left: 100%;
    width: 100%;
  }
}

.avatarInicio {
  vertical-align: middle;
  width: 180px;
  height: 180px;
  border-radius: 20%;
}
</style>
