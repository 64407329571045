import { createStore } from 'vuex'
import { auth } from '../main';
import { signOut } from 'firebase/auth';
//import VuexPersistence from 'vuex-persist';
import { db } from '../main.js';
import { collection, getDocs, query, getDoc, doc } from "firebase/firestore";
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate'
//import { Swal } from '../main';

const ls = new SecureLS({ isCompression: true });

const storeaAutenticacion = createStore({

    plugins: [createPersistedState({
        //variables que quiero que permanezcan incluso si se recarga la pagina
        paths: ["user",
            "entrenadores",
            "nivelesEducativos",
            "areasDeTrabajo",
            "tiposDocumentos",
            "cargos",
            "empresas",
            "paises",
            "entrenadores",
            "generos",
            "sectoresLaborales",
            "arl",
        ],
        overwrite: true,

        storage: {
            getItem: (key) => { return ls.get(key) },
            setItem: (key, value) => { ls.set(key, value) },
            removeItem: (key) => { ls.remove(key) }
        }
    })],
    state: {
        //The user state will initially be null. After login, this state will be updated
        user: "ninguno",
        entrenadores: "ninguno",
        tiposDocumentos: "ninguno",
        generos: "ninguno",
        paises: "ninguno",
        arl: "ninguna",
        sectoresLaborales: "ninguna"
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setEstado(state, payload) {
            console.log("seteando estado " + payload.coleccion + "con datos", payload.datos)
            //console.log("seteando estado "+payload.coleccion+", datos => ",payload.datos);
            state[payload.coleccion] = payload.datos
        },
        setAggObjArrayEstado(state, payload) {
            console.log("Se agregará al estado " + payload.coleccion + ", el objeto => ", payload.obj);
            state[payload.coleccion].push(payload.obj);
        }
    },
    actions: {
        async actualizarUsuarioLogueado(context, datosUsuario) {
            context.commit('setUser', datosUsuario);
        },
        async limpiarEstado(context, datos) {
            context.commit('setEstado', datos);
        },

        async logout(context) {
            console.log(context)
            console.log("se ejecuto el logout en el store");
            signOut(auth);
            /*
            context.commit('setUser', {email:"ninguno", urlImagen:"ninguna"});
            context.commit('setEstado', {coleccion:"generos", datos:"ninguno"});
            context.commit('setEstado', {coleccion:"paises", datos:"ninguno"});
            context.commit('setEstado', {coleccion:"arl", datos:"ninguna"});
            context.commit('setEstado', {coleccion:"sectoresLaborales", datos:"ninguno"}); 
            context.commit('setEstado', {coleccion:"entrenadores", datos:"ninguno"});
            */
        },
        async consultarColeccion(context, coleccion) {
            //console.log("se consultará la coleccion: "+coleccion);

            let datosRta = [];
            const q = query(collection(db, coleccion));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                datosRta.push({ ...doc.data() })
            });
            //console.log("datos encontrados, actualizando en el estado la coleccion "+coleccion)
            //console.log(ciudades);
            context.commit('setEstado', { coleccion: coleccion, datos: datosRta });
        },
        async aggObjArrayEstado(context, datos) {
            console.log("se agregará el objeto " + datos.obj + " a la coleccion: " + datos.coleccion);
            //ESTO NO HA SIDO NECESARIO USARLO POR AHORA
            //context.commit('setAggObjArrayEstado', {coleccion:datos.coleccion, obj:datos.obj});
        }, async consultarArrayObjsDeDoc(context, datos) {
            console.log(datos)
            let ArrObjs = [];
            const docRef = doc(db, datos.coleccion, datos.idDoc);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                //console.log(docSnap.data())
                let claveEntero = 0
                for (let i in docSnap.data()) {
                    claveEntero = parseInt(i)
                    if (claveEntero in docSnap.data()) {
                        ArrObjs.push(docSnap.data()[i]);
                    }
                }
                //console.log("seteando el estado "+datos.estado+" con los datos",ArrObjs);
                context.commit('setEstado', { coleccion: datos.estado, datos: ArrObjs });
            } else {
                alert("error en Vuex, funcion consultarArrayObjsDeDoc(), no existe el documento " + datos.idDoc + " en la coleccion " + datos.coleccion);
            }

        },
        /*
        async crearRegistroLog(context, {emailUsuarioLogueado, id, fecha, accion, objeto, comentario}){
            const docRef = await addDoc(collection(db, "log"), {
                fecha:fecha,
                usuario:emailUsuarioLogueado,
                accion:accion,
                objeto:objeto,
                id:id,                
                comentario:comentario
              });
            console.log("Log guardado con ID: ", docRef.id);
        }
        */
    },
    getters: {
        obtenerUsuarioLogueado: state => {
            return state.user
        },
        obtenerPermisosUsuario: state => {
            return state.user.permisos
        },
        obtenerEntrenadores: state => {
            return state.entrenadores
        },
        obtenerEmpresas: state => {
            return state.empresas
        },
        obtenerCargos: state => {
            return state.cargos
        },
        obtenerAreasDeTrabajo: state => {
            return state.areasDeTrabajo
        }
    }/*, plugins:[new VuexPersistence({storage: window.localStorage}).plugin]*/
})
// export the store
export default storeaAutenticacion

