import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
  path: '/',
  name: 'Inicio',
  component: () => import('../views/inicio.vue'),
  meta: {
    hideNavbar: false,
    title: "ADMON - inicio"
  }
}, {
  path: '/login',
  name: 'login',
  component: () => import('../views/login.vue'),
  meta: {
    hideNavbar: true,
    title: "ADMON - Login"
  }
}, {
  path: '/crearEditarEstudiante',
  name: 'crearEditarEstudiante',
  component: () => import('../views/crearEditarEstudiante.vue'),
  meta: {
    hideNavbar: false,
    title: "ADMON - CREAR ESTUDIANTES"
  }
}, {
  path: '/eliminarEstudiantes',
  name: 'eliminarEstudiantes',
  component: () => import('../views/EliminarEstudiantes.vue'),
  meta: {
    hideNavbar: false,
    title: "ADMON - ELIMINAR ESTUDIANTES"
  }
}, {
  path: '/crearEditarEmpresa',
  name: 'crearEditarEmpresa',
  component: () => import('../views/crearEditarEmpresa.vue'),
  meta: {
    hideNavbar: false,
    title: "ADMON - CREAR CURSO"
  }
}, {
  path: '/crearCargo',
  name: 'crearCargo',
  component: () => import('../views/crearCargo.vue'),
  meta: {
    hideNavbar: false,
    title: "ADMON - CREAR CARGO"
  }
},
{
  path: '/crearAreaDeTrabajo',
  name: 'crearAreaDeTrabajo',
  component: () => import('../views/crearAreaDeTrabajo.vue'),
  meta: {
    hideNavbar: false,
    title: "ADMON - CREAR AREA DE TRABAJO"
  }
},
{
  path: '/crearSectorLaboral',
  name: 'crearSectorLaboral',
  component: () => import('../views/crearSectorLaboral.vue'),
  meta: {
    hideNavbar: false,
    title: "ADMON - CREAR SECTOR LABORAL"
  }
}, {
  path: '/crearEditarCurso',
  name: 'crearEditarCurso',
  component: () => import('../views/crearEditarCurso.vue'),
  meta: {
    hideNavbar: false,
    title: "ADMON - CREAR/EDITAR CURSO"
  }
}, {
  path: '/crearDiploma',
  name: 'crearDiploma',
  component: () => import('../views/crearDiploma.vue'),
  meta: {
    hideNavbar: false,
    title: "ADMON - CREAR DIPLOMA"
  }
}, {
  path: '/generarExcelCurso',
  name: 'generarExcelCurso',
  component: () => import('../views/generarExcelCurso.vue'),
  meta: {
    hideNavbar: false,
    title: "ADMON - GENERAR EXCEL DEL CURSO"
  }
}, {
  path: '/generarExcelCursosProximosVencer',
  name: 'generarExcelCursosProximosVencer',
  component: () => import('../views/GenerarExcelCursosProximosVencer.vue'),
  meta: {
    hideNavbar: false,
    title: "ADMON - GENERAR EXCEL CURSOS PROXIMOS A VENCER"
  }
}, {
  path: '/backUp',
  name: 'backUp',
  component: () => import('../views/backUp.vue'),
  meta: {
    hideNavbar: false,
    title: "ADMON - GENERAR JSON"
  }
},


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
})

export default router
