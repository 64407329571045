const firebaseConfig = {
    apiKey: "AIzaSyDNSkVvY7kkT2ugNb5SRNZ8fkM0pA2ccw0",
    authDomain: "ats-alturas---admon.firebaseapp.com",
    projectId: "ats-alturas---admon",
    storageBucket: "ats-alturas---admon.appspot.com",
    messagingSenderId: "1024138653407",
    appId: "1:1024138653407:web:9fe284751da80a3836436a",
    measurementId: "G-11MY423ZV8"
};

//esta es la key que esta en la consola de reCAPTCHA
//https://www.google.com/u/1/recaptcha/admin/site/587811760/settings
const reCaptchaSiteKey = "6LewSwkjAAAAAHA-0RiXeAvCEfMBQJyNdpIhl-bP";


export { firebaseConfig, reCaptchaSiteKey };