let modoDev = false;

let urlApiBackend;
if (modoDev) {
    //let urlApiBackend = "http://192.168.10.14:4000/api"
    urlApiBackend = "http://localhost:4000/api"
} else {
    urlApiBackend = "https://ats-alturas-nodejs-production.up.railway.app/api";
}

export { modoDev, urlApiBackend };
