import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Swal from "sweetalert2";
//Store - Vuex
import storeaAutenticacion from "./store/index";
//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
//Vue-select
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
//Firebase configuration
import { firebaseConfig, reCaptchaSiteKey } from "../configuraciones";

//Firebase
import { initializeApp } from 'firebase/app';
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { modoDev } from "./urlBackEnd";

const app = initializeApp(firebaseConfig);
//console.log("main.js => esto va antes del appCheck");

//me genera un token para poder usar appcheck en localhost, se registra en el dashboard de appcheck
if (modoDev) { self.FIREBASE_APPCHECK_DEBUG_TOKEN = true; }

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(reCaptchaSiteKey),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
const db = getFirestore(app);
const auth = getAuth(app)
const storage = getStorage(app);

export { db, auth, storage, Swal };
const VueApp = createApp(App);
VueApp.component("v-select", vSelect);
VueApp.use(appCheck);
VueApp.use(router);
VueApp.use(storeaAutenticacion);
VueApp.mount("#app");
